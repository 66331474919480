import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useSnackbar } from 'vue3-snackbar';

export default function useConsultaTemplateForm(service) {
    const store = useStore();
    const snackbar = useSnackbar();

    const state = reactive({
        id: '',
        name: '',
        title: '',
        data: [],
        file: '',
        specialty: '',
        status: 1,
    });

    const disabled = computed(() => {
        if (state.id && state.documentStatus === 2) {
            return true;
        }
        return false;
    });

    const setState = (c) => {
        state.id = c.id;
        state.name = c.name;
        state.title = c.title;
        state.data = c.data;
        state.file = c.file;
        state.specialty = c.specialty;
        state.status = c.status;
    };

    /** ***** Validator ***** */
    const rules = computed(() => ({
        name: { required },
        title: { required },
        data: { required },
        file: { required },
        specialty: { required },
    }));

    const v = useVuelidate(rules, state);

    const resetForm = () => {
        v.value.$reset();
        state.id = '';
        state.name = '';
        state.title = '';
        state.data = [];
        state.file = '';
        state.specialty = '';
        state.status = 1;
    };

    const handleAction = async (action) => {
        if (await v.value.$validate()) {
            let text;
            switch (action) {
                case 'create':
                case 'create_view':
                    await service.createConsultaTemplate(state);
                    if (action === 'create_view') {
                        store.dispatch('ContentManager/openWindow', {
                            id: `ConsultaTemplateForm_${service.consultaTemplate.value.id}`,
                            component: 'ConsultaTemplateForm',
                            name: `Plantilla Consulta ${service.consultaTemplate.value.consecutive}`,
                            params: {
                                title: 'Plantilla Consulta',
                                headerTitle: `Ver Plantilla ${service.consultaTemplate.value.consecutive}`,
                                item: { ...service.consultaTemplate.value },
                            },
                        });
                    }
                    text = 'Plantilla creda con exito';
                    resetForm();
                    break;
                case 'save':
                    await service.updateConsultaTemplate(state.id, state);
                    setState(service.consultaTemplate.value);
                    text = 'Plantilla actualizada con exito';
                    break;
                default:
                    break;
            }
            snackbar.add({
                type: 'success',
                text,
            });
        } else {
            console.log(v.$errors);
            snackbar.add({
                type: 'error',
                text: 'Ups no podemos enviar la informacion sin algunos datos',
            });
        }
    };

    return {
        v,
        state,
        disabled,
        setState,
        resetForm,
        handleAction,
    };
}
