import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function () {
    const consultaTemplate = ref(null);
    const consultaTemplates = ref([]);

    const getConsultaTemplate = async (id) => {
        const res = await axios.get(`/care/consultas/templates/${id}`);

        if (res.status === 200) {
            consultaTemplate.value = res.data;
        }
    };

    const getConsultaTemplates = async (searchQuery = '') => {
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/consultas/templates${query}`);

        if (res.status === 200) {
            consultaTemplates.value = res.data.items;
        }
    };

    const createConsultaTemplate = async (data) => {
        const res = await axios.post('/care/consultas/templates', data);
        if (res.status === 201) {
            // console.log(res.data);
            consultaTemplate.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateConsultaTemplate = async (id, data) => {
        const res = await axios.put(`/care/consultas/templates/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            consultaTemplate.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        consultaTemplate,
        consultaTemplates,
        getConsultaTemplate,
        getConsultaTemplates,
        createConsultaTemplate,
        updateConsultaTemplate,
    };
}
